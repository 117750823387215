import { memo } from 'react';
import ReactGA4 from 'react-ga4';

import logo_1 from '../../images/header-secondary/021.webp';
import logo_2 from '../../images/header-secondary/art.webp';
import logo_3 from '../../images/header-secondary/bulevar.webp';
import logo_4 from '../../images/header-secondary/city.webp';
import logo_5 from '../../images/header-secondary/eho.webp';
import logo_6 from '../../images/header-secondary/exit.webp';
import logo_7 from '../../images/header-secondary/grad.webp';
import logo_8 from '../../images/header-secondary/halo.webp';
import logo_9 from '../../images/header-secondary/info.webp';
import logo_10 from '../../images/header-secondary/king.webp';
import logo_11 from '../../images/header-secondary/klub.webp';
import logo_12 from '../../images/header-secondary/max.webp';
import logo_13 from '../../images/header-secondary/office.webp';
import logo_14 from '../../images/header-secondary/one.webp';
import logo_15 from '../../images/header-secondary/play.webp';
import logo_16 from '../../images/header-secondary/royal.webp';
import logo_17 from '../../images/header-secondary/spens.webp';
import logo_18 from '../../images/header-secondary/stan.webp';
import logo_19 from '../../images/header-secondary/trg.webp';
import logo_20 from '../../images/header-secondary/win.webp';

import './HeaderSecondary.scss';

const HeaderSecondary = () => {
  const baners = [
    {
      img: logo_1,
      link: 'https://021-nekretnine.rs',
    },
    {
      img: logo_2,
      link: 'https://art-nekretnine.rs',
    },
    {
      img: logo_3,
      link: 'https://bulevar-nekretnine.rs',
    },
    {
      img: logo_4,
      link: 'https://city-nekretnine.rs',
    },
    {
      img: logo_5,
      link: 'https://eho-nekretnine.rs',
    },
    {
      img: logo_6,
      link: 'https://exit-nekretnine.rs',
    },
    {
      img: logo_7,
      link: 'https://grad-nekretnine.rs',
    },
    {
      img: logo_8,
      link: 'https://halo-nekretnine.rs',
    },
    {
      img: logo_9,
      link: 'https://info-nekretnine.rs',
    },
    {
      img: logo_10,
      link: 'https://king-nekretnine.rs',
    },
    {
      img: logo_11,
      link: 'https://klub-nekretnine.rs',
    },
    {
      img: logo_12,
      link: 'https://max-nekretnine.rs',
    },
    {
      img: logo_13,
      link: 'https://office-nekretnine.rs',
    },
    {
      img: logo_14,
      link: 'https://one-nekretnine.rs/',
    },
    {
      img: logo_15,
      link: 'https://play-nekretnine.rs',
    },
    {
      img: logo_16,
      link: 'https://royal-nekretnine.rs',
    },
    {
      img: logo_17,
      link: 'https://spens-nekretnine.rs',
    },
    {
      img: logo_18,
      link: 'https://stan-nekretnine.rs',
    },
    {
      img: logo_19,
      link: 'https://trg-nekretnine.rs',
    },
    {
      img: logo_20,
      link: 'https://win-nekretnine.rs',
    },
  ];
  const baner = baners[Math.floor(Math.random() * baners.length)];

  const analyticsEvent = () => {
    ReactGA4.event({
      category: 'Ad',
      action: 'Ns-Group',
    });
  };

  return (
    <div className='header-secondary'>
      <a href={baner.link} target='_blank' rel='noopener noreferrer' onClick={analyticsEvent}>
        <img src={baner.img} alt='' />
      </a>
    </div>
  );
};

export default memo(HeaderSecondary, () => true);
